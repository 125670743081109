@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-Thin.eot');
    src: url('../fonts/ProductSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-Thin.woff2') format('woff2'),
        url('../fonts/ProductSans-Thin.woff') format('woff'),
        url('../fonts/ProductSans-Thin.ttf') format('truetype'),
        url('../fonts/ProductSans-Thin.svg#ProductSans-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-ThinItalic.eot');
    src: url('../fonts/ProductSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-ThinItalic.woff2') format('woff2'),
        url('../fonts/ProductSans-ThinItalic.woff') format('woff'),
        url('../fonts/ProductSans-ThinItalic.ttf') format('truetype'),
        url('../fonts/ProductSans-ThinItalic.svg#ProductSans-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-Light.eot');
    src: url('../fonts/ProductSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-Light.woff2') format('woff2'),
        url('../fonts/ProductSans-Light.woff') format('woff'),
        url('../fonts/ProductSans-Light.ttf') format('truetype'),
        url('../fonts/ProductSans-Light.svg#ProductSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-LightItalic.eot');
    src: url('../fonts/ProductSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-LightItalic.woff2') format('woff2'),
        url('../fonts/ProductSans-LightItalic.woff') format('woff'),
        url('../fonts/ProductSans-LightItalic.ttf') format('truetype'),
        url('../fonts/ProductSans-LightItalic.svg#ProductSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-Regular.eot');
    src: url('../fonts/ProductSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-Regular.woff2') format('woff2'),
        url('../fonts/ProductSans-Regular.woff') format('woff'),
        url('../fonts/ProductSans-Regular.ttf') format('truetype'),
        url('../fonts/ProductSans-Regular.svg#ProductSans-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-Italic.eot');
    src: url('../fonts/ProductSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-Italic.woff2') format('woff2'),
        url('../fonts/ProductSans-Italic.woff') format('woff'),
        url('../fonts/ProductSans-Italic.ttf') format('truetype'),
        url('../fonts/ProductSans-Italic.svg#ProductSans-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-Medium.eot');
    src: url('../fonts/ProductSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-Medium.woff2') format('woff2'),
        url('../fonts/ProductSans-Medium.woff') format('woff'),
        url('../fonts/ProductSans-Medium.ttf') format('truetype'),
        url('../fonts/ProductSans-Medium.svg#ProductSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-MediumItalic.eot');
    src: url('../fonts/ProductSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-MediumItalic.woff2') format('woff2'),
        url('../fonts/ProductSans-MediumItalic.woff') format('woff'),
        url('../fonts/ProductSans-MediumItalic.ttf') format('truetype'),
        url('../fonts/ProductSans-MediumItalic.svg#ProductSans-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-Bold.eot');
    src: url('../fonts/ProductSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-Bold.woff2') format('woff2'),
        url('../fonts/ProductSans-Bold.woff') format('woff'),
        url('../fonts/ProductSans-Bold.ttf') format('truetype'),
        url('../fonts/ProductSans-Bold.svg#ProductSans-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-BoldItalic.eot');
    src: url('../fonts/ProductSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/ProductSans-BoldItalic.woff') format('woff'),
        url('../fonts/ProductSans-BoldItalic.ttf') format('truetype'),
        url('../fonts/ProductSans-BoldItalic.svg#ProductSans-BoldItalic') format('svg');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-BlackItalic.eot');
    src: url('../fonts/ProductSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-BlackItalic.woff2') format('woff2'),
        url('../fonts/ProductSans-BlackItalic.woff') format('woff'),
        url('../fonts/ProductSans-BlackItalic.ttf') format('truetype'),
        url('../fonts/ProductSans-BlackItalic.svg#ProductSans-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/ProductSans-Black.eot');
    src: url('../fonts/ProductSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProductSans-Black.woff2') format('woff2'),
        url('../fonts/ProductSans-Black.woff') format('woff'),
        url('../fonts/ProductSans-Black.ttf') format('truetype'),
        url('../fonts/ProductSans-Black.svg#ProductSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}