@import "../public/css/fonts.scss";
@import "../public/css/variables.scss";
@import "../public/css/mixins.scss";

::-webkit-scrollbar {
  width: 5px;
  height: 5px;

  &-thumb {
    background: lighten($c_primary, 75%);
  }

  &-track {
    background-color: lighten($c_primary, 95%);
  }
}

* {
  margin: 0;
  outline: none !important;
  font-family: $f_body !important;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $c_white;
}

a {
  color: $c_body;
  transition: $transition;

  &:hover {
    color: $c_primary;
  }

  &,
  &:not(.btnn):hover {
    text-decoration: none;
  }
}

b,
strong {
  font-weight: 700;
}

:is(h1, h2, h3, h4, h5, h6) {
  color: $c_heading;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0;

  strong,
  b {
    font-weight: 600;
  }
}

:where(h1, h2, h3, h4, h5, h6):not(:last-child) {
  margin-bottom: 15px;
}

:is(body, p, li) {
  color: $c_body;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  @media screen and (max-width: 767.98px) {
    font-size: 14px;
  }
}

:is(p, li) {
  margin: 0;
}

:where(p, ul, ol, li):not(:last-child) {
  margin-bottom: 15px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

.site_header {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $c_primary;
  padding: 20px 15px 18px;
  text-align: center;
  width: 100%;
  z-index: 1;

  @media screen and (max-width: 767.98px) {
    padding: 25px 15px 40px;
    background: transparent
      linear-gradient(to bottom, lighten($c_primary, 10%) 5%, transparent 100%);
    pointer-events: none;
  }

  figure {
    display: inline-flex;

    img {
      height: 25px;
    }
  }
}

.content {
  min-height: 100vh;
  padding-top: 63px;
  overflow: clip;

  @media screen and (max-width: 767.98px) {
    padding-top: 0;
  }
}

.tracking_sc {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 15px;

  @media screen and (max-width: 767.98px) {
    padding: 0;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh;
  }

  .lt_s,
  .rt_s {
    width: 50%;

    @media screen and (max-width: 767.98px) {
      width: 100%;
    }
  }

  .lt_s {
    padding: 15px 0;
    position: sticky;
    top: 63px;

    @media screen and (max-width: 767.98px) {
      padding: 0;
      position: initial;
    }

    .map {
      height: calc(100vh - (63px + (15px * 2)));

      @media screen and (max-width: 767.98px) {
        height: calc(100vh - 140px);
        min-height: 400px;
      }

      figure {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          inset: 0;
        }
      }
    }
  }

  .rt_s {
    padding: 50px 0 50px 15px;
    margin-inline: auto;
    align-self: center;
    max-width: 550px;

    @media screen and (max-width: 1399.98px) {
      padding: 40px 0 40px 15px;
      max-width: 500px;
    }

    @media screen and (max-width: 1199.98px) {
      padding: 30px 0 30px 25px;
    }

    @media screen and (max-width: 767.98px) {
      padding: 30px 20px;
      border-radius: 30px 30px 0 0;
      margin-top: -30px;
      position: relative;
      background: $c_white;
      box-shadow: 0px 0px 22px 0px #00000017;
      z-index: 2;
      flex: 1;
    }
    .booking_no {
      color: black;
      margin: 0;
      padding-bottom: 10px;
      font-size: 14px;
    }
    h1 {
      font-size: 30px;
      text-transform: capitalize;
      margin-bottom: 20px;

      @media screen and (max-width: 1399.98px) {
        font-size: 26px;
        margin-bottom: 15px;
      }

      @media screen and (max-width: 767.98px) {
        font-size: 22px;
      }
    }

    .track_detail {
      li {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        color: $c_black;
        position: relative;
        &:first-child {
          background: #fdffff;
          padding: 15px;
          border-radius: 10px;
          border: 1px solid #e0e0e0;
        }

        span {
          color: $c_body;
          font-size: 14px;
          margin-bottom: 3px;
          letter-spacing: 0.3px;

          @media screen and (max-width: 1399.98px) {
            margin-bottom: 2px;
          }

          @media screen and (max-width: 767.98px) {
            font-size: 12px;
          }
        }

        strong {
          font-weight: 600;
        }

        .image_flex {
          display: flex;
          align-items: center;
          gap: 15px;

          @media screen and (max-width: 1399.98px) {
            gap: 10px;
          }

          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;

            @media screen and (max-width: 1399.98px) {
              width: 65px;
              height: 65px;
            }
          }

          figcaption {
            display: flex;
            flex-direction: column;
            gap: 1px;

            span {
              margin-bottom: 0;
              letter-spacing: 0;
            }
          }
        }
        .call_to {
          position: absolute;
          right: 20px;
          top: 50%;
          background: black;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border-radius: 50%;
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }

    .track_status {
      li {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        padding-left: 35px;
        min-height: 24px;
        color: #ffffff;
        background: #fdffff;
        padding: 15px 15px 15px 30px;
        border-radius: 10px;
        margin-bottom: 20px;
        min-height: 72px;
        justify-content: center;
        background: #000;

        @media screen and (max-width: 767.98px) {
          min-height: 21px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: "";
          top: 50%;
          left: 10px;
          width: 10px;
          height: 10px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border-radius: 30px;
          color: #ffffff;
          z-index: 2;
          transform: translateY(-50%);
        }

        // &:not(:last-child):after {
        //   content: "";
        //   position: absolute;
        //   left: 12px;
        //   top: 0;
        //   width: 1px;
        //   height: 100%;
        //   background-color: $c_black;
        //   z-index: 1;
        // }

        strong {
          font-weight: 500;
          @media (max-width: 420px) {
            font-size: 13px;
          }
        }

        span {
          font-size: 12px;
          color: $c_body;
          line-height: 1.2;
          margin-top: 1px;
        }
      }
    }
  }
}

.map {
  width: 100%;
  height: 440px;

  .map_container {
    width: 100%;
    height: 440px;
  }

  figure {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom center;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}
